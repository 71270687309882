import { Component, EventEmitter, Input, KeyValueDiffers, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { fadeInAnimation } from 'app/route-animations';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { ModalService } from 'common/modal/modal.service';
import { environment } from 'environments/environment';
import { Field } from 'models/field';
import { Module } from 'models/module';
import { PmsPaymentService } from 'payment_service';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { UserActionType } from 'pms_enums';
import { PmsService } from 'pms_service';
import { skip } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';

@Component({
  selector: 'app-pms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInAnimation]
})

export class PmsLoginComponent extends PmsCiCoBaseDirective implements OnInit, OnDestroy {
  qrActive: boolean;
  doorActive: boolean;
  terminal: boolean;
  simplifiedLogin: boolean;
  scannerEnabled: boolean;
  kioskScanner: boolean;
  confirm_name = 'send';
  defaultLogin = true;
  legal: any;
  list: any;

  search: string;
  email: string;
  address: string;
  date: Date;

  resFiledName = 'reservation_id';
  nameFiledName = 'last_name';

  loginData = {
    id: '',
    reservation_id: '',
    last_name: '',
    mod: '',
    field_values: []
  };

  @ViewChild('serviceForm') serviceForm: NgForm;
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @ViewChild('reservation_id', {static: true, read: NgModel}) reservationIdNgModel!: NgModel;
  @Output() reservation_data = new EventEmitter<any>();
  @Input() mod: string;
  @Input() wizardModule: Module;
  valid: boolean;
  title = 'service.login.title';

  constructor(public globals: Globals,
              public cicoService: PmsCiCoService,
              private pmsService: PmsService,
              private businessService: BusinessService,
              protected differs: KeyValueDiffers,
              protected storageService: StorageService,
              protected guestService: GuestService,
              protected modalService: ModalService,
              protected paymentService: PmsPaymentService,
              protected route: ActivatedRoute) {
    super(cicoService, guestService, differs, storageService, globals, modalService, paymentService, route);
  }

  ngOnInit() {
    document.getElementById('container')?.classList?.add('cico');
    super.ngOnInit();

    this.cicoService.logUnload = false;
    this.legal = this.globals.business?.legal;
    this.terminal = this.globals.terminalKiosk() || this.globals.hardwareTerminalKiosk();

    this.init();
    if (this.terminal) {
      this.scannerEnabled = this.wizardModule?.settings?.qr_code_scan && (!this.globals.isMacOrIos() || this.globals.isSafari());
      this.kioskScanner = this.globals.place.kiosk_scanner;

      this.subscriptions.add(this.businessService.currentLocale.pipe(skip(1)).subscribe(_locale => {
        this.pmsService.get_texts(this.wizardModule.type).subscribe((response: any) => {
          this.wizardModule.fields.forEach((field, _index) => {
            const updated = response.module.fields.find((ufield: Field) => field.identifier === ufield.identifier);
            if (updated) {
              field.name = updated.name;
              field.description = updated.description;
            }
          });
        });
      }));
    }

    this.subscriptions.add(this.guestService.loginRequired.subscribe((loginRequired: boolean) => {
      if (loginRequired) {
        this.cicoService.disableNextButton(false);
        this.route.queryParams.subscribe((query: any) => {
          if (query?.reservation) {
            this.loginData.reservation_id = query.reservation;
            this.globals.removeQuery();
          }
        });
      }
    }));

    this.subscriptions.add(this.cicoService.navigation.subscribe(type => {
      switch (type) {
        case UserActionType.back:
          this.cicoService.idleSubj.next(true);
          break;
        case UserActionType.cancel:
          this.onScanAbort();
          this.cicoService.idleSubj.next(true);
          break;
        default:
          break;
      }
    }));
  }

  onSubmit() {
    this.globals.clearAlert();
    this.globals.markAllControlsTouched(this.serviceForm);
    if (this.serviceForm?.valid) {
      this.globals.sending();
      this.title = 'service.login.title';
      this.loginData.mod = this.mod;
      this.pmsService.get_reservation_by(this.loginData).subscribe((success: any) => {
        this.cicoService.logUnload = true;
        Array.isArray(success) ? this.list = success : this.login(success);
      }, response => {
        const config = response.error.time ? {timeOut: response.error.time, progressBar: true} : {};
        this.globals.alert('error', response.error.message, response.error.title, config);
        this.init();
        if (response.status === 403) {
          this.reset();
        }
      });
      this.valid = false;
    } else {
      this.valid = true;
    }
  }

  onScanSuccess(data) {
    this.qrActive = false;
    if (data.errorText) {
      const error = data.errorText.error;
      this.globals.alert(error.type, error.message, error.title);
    } else {
      this.reservation_data.emit(data.reservationData);
      this.cicoService.toggleInactivity(true);
    }
  }

  login(success) {
    this.subscriptions.unsubscribe();
    this.reservation = success.reservation;
    this.reservation_data.emit({reservation: success.reservation, options: success.options});

    if (!this.globals.place.wizard && this.globals.guest) {
      if (success.email) {
        this.globals.guest.email = success.email;
      }
      this.globals.guest.subscription_id = success.subscription_id;
      this.globals.guest.smart_feedback = success.smart_feedback;
      this.globals.guest.save();
    }
    if (success.place) {
      this.guestService.setPlace(success.place);
    }

    this.cicoService.loggedInSubj.next(true);
    this.guestService.loginRequiredSubj.next(false);
    this.init();
  }

  choose(id) {
    this.list = null;
    this.loginData.id = id;
    this.pmsService.get_reservation_by(this.loginData).subscribe((success: any) => {
      this.login(success);
    });
  }

  onScanAbort() {
    this.qrActive = false;
    window.scrollTo(0, 0);
  }

  init() {
    this.setLoginData();
    this.list = null;
    this.cicoService.suppressGuardSubj.next(true);
    this.globals.init_send_cancel_btn();
    window.scrollTo(0, 0);

    if (environment.production && this.globals.place?.wizard) {
      this.resFiledName = Math.random().toString(36).substring(2);
      this.nameFiledName = Math.random().toString(36).substring(2);
    }
  }

  reset() {
    this.setLoginData();
    this.serviceForm?.resetForm();
    window.scrollTo(0, 0);
  }

  setLoginData() {
    this.defaultLogin = true;
    this.simplifiedLogin = this.terminal ? this.wizardModule?.settings?.simplified_login : false;
    this.cicoService.hideNextButton(true);
    this.loginData = {
      id: '',
      reservation_id: '',
      last_name: '',
      mod: '',
      field_values: []
    };
    this.serviceForm?.controls['reservation_id']?.reset();
    this.serviceForm?.controls['last_name']?.reset();
    this.reservationIdNgModel?.reset();
  }

  simplifiedLoginPage() {
    this.defaultLogin = !this.defaultLogin;
    this.title = 'service.login.simplified_title';
    this.valid = false;
  }

  back() {
    this.defaultLogin = !this.defaultLogin;
    this.title = 'service.login.title';
    this.valid = false;
  }

  useQrCode() {
    this.qrActive = true;
  }

  ngOnDestroy(): void {
    this.globals.send_cancel_disabled = false;
    super.ngOnDestroy();
  }
}
